<template>
  <div id='knowledge-base'>
    <v-row>
      <v-col cols='12' md='3' lg='3' order='1' order-md='2' class='knowledge-base-summary-overlay'>
        <v-card class='knowledge-base-summary'>
          <div class='kb-questions py-1'>
            <div v-for='paragraph in customersIntegrationSummary' :key='paragraph.title'>
              <div class='kb-question d-flex align-start text-sm px-5 my-4 cursor-pointer'>
                <v-icon size='14' class='me-2 mt-1' color='secondary'>
                  {{ icons.mdiFileDocumentMultiple }}
                </v-icon>
                <span class='text--secondary'
                      @click='scrollToElement(paragraph.link)'>{{ paragraph.title }}</span>
              </div>

              <ul v-if='paragraph.children'>
                <li v-for='subparagraph in paragraph.children' :key='subparagraph.title'
                    class='kb-question d-flex align-start text-sm px-5 my-4 cursor-pointer'>
                  <v-icon size='14' class='me-2 mt-1' color='secondary'>
                    {{ icons.mdiTextBoxOutline }}
                  </v-icon>
                  <span class='text--secondary'
                        @click='scrollToElement(subparagraph.link)'>{{ subparagraph.title }}</span>
                </li>
              </ul>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols='12' md='9' lg='9' order='1' order-md='2'>
        <v-card>
          <v-card-title class='flex-nowrap'>
            <span class='text-break'>Merchanto Payments: Customers Integration</span>
          </v-card-title>
          <v-card-subtitle class='flex-nowrap'>
            <span class='text-break'>Connecting Customers to Merchanto Payments</span>
          </v-card-subtitle>

          <v-card-title id='general-info'>1. General information:</v-card-title>
          <v-card-text>
            The Merchanto platform facilitates payments using LPB Bank and the Stripe payment
            provider. To onboard customers, it is necessary to establish customer accounts with the
            preferred payment provider(s) – LPB Bank, Stripe, or both. Subsequently, link these
            accounts to the Merchanto platform and furnish customers with the requisite data. This
            documentation provides a detailed walkthrough of each essential step in the process.
          </v-card-text>

          <v-card-title id='payment-providers'>2. Payment provider's customer account</v-card-title>
          <v-card-text>
            Prior to integrating customers, it is necessary to establish accounts within LPB or
            Stripe (or both) payment providers under the overarching Merchanto account. This implies
            that a primary Merchanto account must already exist, and you must then create connected
            customer accounts beneath it. The following section outlines the step-by-step process
            for accomplishing this.
          </v-card-text>

          <v-card-title id='payment-providers-lpb'>2.1 LPB customer account</v-card-title>
          <v-card-text>
            <p>For the integration of an LPB account with the Merchanto platform, three key
              characteristics are required:
              <strong>MID</strong>, <strong>KeyIndex</strong>, and <strong>Gateway Key</strong>.</p>

            <p class='text-break'><strong>MID</strong> is a merchant descriptor, representing a
              customer ID on the LPB platform. It can be located under the "Merchants" tab.
              <span class='docs-open-screenshot'
                    @click.stop='openModal("lpb-mid")'>Click here</span>
              to view screenshot of its location.
            </p>
            <p class='text-break'><strong>Gateway Key</strong> refer to RSA key essential for
              encrypting and decrypting data exchanged with the LPB API. Please click the <strong>Generate
                RSA key pair</strong> button at the top of the card (<span
                class='docs-open-screenshot' @click.stop='openModal("merchanto-generate-rsa", "l")'>see example</span>).
              This action will generate new
              RSA keys for this account. <strong>Private RSA Key</strong> will be automatically
              upload to the S3 bucket and the <strong>Public RSA key</strong> will be shown at the
              response modal (<span class='docs-open-screenshot'
                                    @click.stop='openModal("merchanto-public-rsa", "l")'>see example</span>).
              Please copy this key (using the <strong>copy</strong> button or manually) and upload
              it to the LPB account. To upload a new key, navigate to
              the <em>ACTIONS</em> column, click on the <strong>Keys</strong> button, and then
              select <strong>Add new key</strong> adjacent to the merchant name. In the prompted
              window, paste the generated public key and click the <strong>Create</strong> button.
              Please, <span
                class='docs-open-screenshot'
                @click.stop='openModal("lpb-add-keys")'>see the instructions</span> video.
              After uploading the public
              key, download and save the <strong>Gateway Key</strong> from your LPB account. For the
              next step, upload the downloaded <strong>Gateway Key</strong> to the <span
                class='docs-open-screenshot'
                @click.stop='openModal("merchanto-upload-gateway-key", "l")'>corresponding field</span>
              at the Merchant
              settings tab.</p>
            <p class='text-break'>Take note of the displayed <strong>Key index</strong>. This
              identifier will be required when adding a customer to the Merchanto platform. <span
                class='docs-open-screenshot'
                @click.stop='openModal("lpb-key-index")'>Click here</span>
              to view where to find the <strong>Key index</strong> parameter.</p>
          </v-card-text>

          <v-card-title id='payment-providers-stripe'>2.2 Stripe customer account</v-card-title>
          <v-card-text>
            <p class='text-break'>Before proceeding with integration into the Merchanto platform,
              take the following steps to create a Stripe Connected account for your customer:</p>
            <ul>
              <li>1. Begin by creating a Stripe Connected account for your customer. Detailed
                information about Stripe Connected accounts can be found in the <a
                  href='https://stripe.com/docs/connect' target='_blank' rel='noopener'>Stripe
                  documentation</a>.
              </li>
              <li>2. To integrate the Stripe account with the Merchanto platform, you'll need the
                unique ID associated with this account. Navigate to the <strong>Connect</strong>
                tab, locate and click on your client's account, then scroll down to the <strong>Profile</strong>
                section. For a visual guide, refer to this <span class='docs-open-screenshot'
                                                                 @click.stop='openModal("stripe-account-id")'>screenshot</span>
                illustrating the Stripe account ID location.
              </li>
            </ul>
          </v-card-text>

          <v-card-title id='connect-customer-to-merchanto'>3. Connect customer to Merchanto
            platform
          </v-card-title>
          <v-card-text>
            <p class='text-break'>After creating customer accounts, proceed to provide the necessary
              information within the Merchanto platform.</p>

            <ul>
              <li>
                <h4>Activate Payment Feature:</h4>
                <p class='text-break'>Head to the <strong>Services</strong> tab and activate the
                  payment feature for the customer. Remember to save changes. For a visual guide,
                  refer to this <span class='docs-open-screenshot'
                                      @click.stop='openModal("merchanto-service-activation")'>screenshot</span>.
                </p>
              </li>
              <li>
                <h4>Configure Payment Settings:</h4>
                <p class='text-break'>Navigate to the Websites list, locate the required account,
                  click on <strong>Actions</strong> menu and access the Payment Settings page.</p>
              </li>
              <li>
                <h4>API Key for Payment Integrations:</h4>
                <p class='text-break'>On the Payment settings page, find the <strong>Website Payment
                  API Key</strong> block at the top. Share this key with your merchant for payment
                  integrations. It acts as a secure key for verifying payment information and
                  authorizing customers. Note that manual changes to this key are not possible; it
                  can only be generated or regenerated entirely. This section is enabled once you
                  enable one of the payment providers for the customer.</p>
              </li>
              <li>
                <h4>Adding LPB Account:</h4>
                <p class='text-break'>To add an LPB account, paste the merchant's
                  <strong>MID</strong> and <strong>Key Index</strong>, save the settings, and upload
                  RSA keys (<strong>Secret key</strong> you generated and the <strong>Gateway
                    key</strong> downloaded from the LPB platform). Refer to point 2.1 in this
                  documentation for details on retrieving these parameters and keys.</p>
              </li>
              <li>
                <h4>Adding Stripe Account:</h4>
                <p class='text-break'>To add a Stripe account, paste the merchant's ID and Fee, and
                  save the settings. Details on obtaining the Stripe Connected account ID can be
                  found in point 2.2 of this guide. The Fee is a custom percentage amount charged
                  over the payment amount.</p>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model='modalDialog' :max-width='modalSize'>
      <img :src='screenshot' alt=''>
    </v-dialog>
  </div>
</template>

<script>
import { mdiTextBoxOutline, mdiFileDocumentMultiple } from '@mdi/js';
import useDocs from '@/views/docs/useDocs';
import { ref } from '@vue/composition-api';

export default {
  setup() {
    const { scrollToElement } = useDocs();

    const customersIntegrationSummary = [
      { title: '1. General information', link: 'general-info' },
      {
        title: '2. Payment provider\'s customer account', link: 'payment-providers', children: [
          { title: '2.1 LPB customer account', link: 'payment-providers-lpb' },
          { title: '2.2 Stripe customer account', link: 'payment-providers-stripe' },
        ],
      },
      { title: '3. Connect customer to Merchanto platform', link: 'connect-customer-to-merchanto' },
    ];

    const screenshot = ref(null);
    const modalDialog = ref(false);
    const screenshotsMapping = {
      'lpb-mid': require('@/assets/images/docs/payment/lpb-mid.png'),
      'lpb-add-keys': require('@/assets/images/docs/payment/lpb-add-keys.gif'),
      'lpb-key-index': require('@/assets/images/docs/payment/lpb-key-index.png'),
      'stripe-account-id': require('@/assets/images/docs/payment/stripe-account-id.png'),
      'merchanto-service-activation': require('@/assets/images/docs/payment/merchanto-service-activation.png'),
      'merchanto-generate-rsa': require('@/assets/images/docs/payment/merchanto-generate-rsa.png'),
      'merchanto-upload-gateway-key': require('@/assets/images/docs/payment/merchanto-upload-gateway-key.png'),
      'merchanto-public-rsa': require('@/assets/images/docs/payment/merchanto-public-rsa.png'),
    };

    const modalSize = ref(undefined);
    const openModal = (screenshotId, size) => {
      switch (size) {
        case 'sm':
          modalSize.value = '450px';
          break;
        case 'l':
          modalSize.value = '700px';
          break;
        case 'xl':
          modalSize.value = '950px';
          break;
        case undefined:
        default:
          modalSize.value = undefined;
          break;
      }
      screenshot.value = screenshotsMapping[screenshotId];
      modalDialog.value = true;
    };

    return {
      customersIntegrationSummary,
      icons: { mdiTextBoxOutline, mdiFileDocumentMultiple },
      screenshot,
      modalDialog,
      openModal,
      scrollToElement,
      modalSize,
    };
  },
};
</script>

<style lang='scss' scoped>
@import '@core/preset/preset/pages/knowledge-base.scss';

.docs-scheme {
  transition: all .3s;

  &:hover {
    opacity: .5;
  }
}

.docs-open-screenshot {
  text-decoration: underline;
  color: var(--v-anchor-base);
  cursor: pointer;
}

.docs-scheme img {
  max-width: 100%;
}
</style>
